import React from 'react';
import PropTypes from 'prop-types';

export default function FeatureIcon({ icon, title }) {
  return (
    <div className="flex flex-col items-center gap-y-2">
      {icon}
      <div className="font-medium text-sm">{title}</div>
    </div>
  );
}

FeatureIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};
