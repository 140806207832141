import React from 'react';
import PropTypes from 'prop-types';
import channelData from 'data/ChannelData';
import { RiKakaoTalkFill } from 'react-icons/ri';
import { MdArrowUpward } from 'react-icons/md';
import { SiGraylog } from 'react-icons/si';

export default function Quickmenu({ bottom, right, className }) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <div className={`flex flex-col gap-3 fixed ${className}`} style={{ bottom: `${bottom}px`, right: `${right}px` }}>
      <button
        onClick={() => {
          window.open(channelData.kakao, '_blank');
        }}
        type="button"
        className="rounded-full bg-yellow-300 p-3 shadow-xl"
      >
        <RiKakaoTalkFill size={28} />
      </button>
      <button onClick={scrollToTop} type="button" className="rounded-full p-3 bg-white shadow-xl  border border-gray-100">
        <MdArrowUpward size={28} className="text-gray-600" />
      </button>
    </div>
  );
}

Quickmenu.propTypes = {
  className: PropTypes.string,
  bottom: PropTypes.number,
  right: PropTypes.number,
};

Quickmenu.defaultProps = {
  className: '',
  bottom: 24,
  right: 20,
};
