module.exports = [
  {
    id: 1,
    category: 1,
    title: '예약 방법',
    body: `앱[App]이용

1. The편한여행[더편한여행] 앱[App]을 다운로드하여 주세요.
2. 원하시는 서비스, 이용일시와 장소를 입력해주세요.
3. 탑승 인원 및 수화물을 선택해 주세요.
4. 정보 입력 후 결제를 진행해주세요.
    
카카오채널 이용

1. 카카오채널 @더편한여행을 검색하여 주세요.
2. 원하시는 서비스, 이용일시와 장소를 말씀해주세요.
3. 상담 후 이용 가능 여부, 예약 절차를 안내 받으세요.`,
  },
  {
    id: 2,
    category: 1,
    title: '예약 취소 및 변경',
    body: `예약 취소 및 변경은 카카오채널 @더편한여행으로 부탁 드립니다.
예약 확정 시 서비스 이용일자(시간)와 장소 변경은 어려울 수 있는 점 양해 부탁 드립니다.`,
  },
  {
    id: 3,
    category: 1,
    title: '안전벨트 착용',
    body: `회원님의 보다 안전한 이동을 위하여, 전좌석 안전벨트 착용을 의무화하고 있습니다.
안전벨트 착용을 거부하실 경우, 서비스 이용이 불가할 수 있습니다.`,
  },
  {
    id: 4,
    category: 1,
    title: '반려동물 동반 탑승',
    body: `반려동물과 함께 이동이 가능합니다.
단, 반려견 동반 시 이동 가방 및 켄넬을 이용하셔야 동반 탑승이 가능하십니다.
켄넬 혹은 이동 가방 미사용으로 인한 피해가 발생할 경우 비용이 청구될 수 있습니다.`,
  },
  {
    id: 5,
    category: 1,
    title: '수화물 규정',
    body: `The편한 이동을 위하여 트렁크에 짐 적재가 가능하십니다. 
단, 무리한 짐 적재로 인한 차량 파손이 이어지고 있어 아래와 같은 사항은 금지하고 있습니다.
* 배차 된 차량 트렁크(짐칸)에 수용할 수 없을 만큼 많은 양의 짐인 경우
(가구류 및 TV등 부피가 큰 짐의 경우 운행 중 차량 충격 발생 우려로 적재 불가)
* 좌석 시트 혹은 바닥 카펫을 손상시킬 수 있는 짐인 경우
* 휴대할 수 없는 물건을 무리하게 들고 좌석에 탑승하는 경우 

이 외에도 파손의 위험이 우려되거나 현장에서 적재가 불가하다고 판단되면 서비스 이용이 불가함을 고지할 수 있습니다.`,
  },
  {
    id: 6,
    category: 1,
    title: '경유지 설정',
    body: '목적지 외 다른 곳 경유 시 사전 공유 시 설정 가능하며, 추가 금액이 발생합니다.',
  },
  {
    id: 7,
    category: 1,
    title: '차량 파손 및 오염',
    body: '서비스 이용 중 차량 내부 및 외부를 손상 혹은 오염시킨 경우 비용이 발생할 수 있습니다.',
  },
  {
    id: 8,
    category: 2,
    title: '카드 등록',
    body: 'The편한여행 어플리케이션(App)을 통하여 카드를 등록하시면 보다 더 편리하게 결제 가능하십니다.',
  },
  {
    id: 9,
    category: 2,
    title: '수수료 적용',
    body: `[7일 전 취소] 100% 전액 환불
[4일 ~ 6일 전] 결제 금액의 20% 수수료 부과 
[1일 ~ 3일 전] 결제 금액의 40% 수수료 부과 
[당일 / No-Show] 전액 환불 불가`,
  },
  {
    id: 10,
    category: 2,
    title: '환불',
    body: `* 정확하지 않은 장소 및 시간 기입 시 환불 불가합니다.
* 픽업 장소 도착 후 90분 이내에 차량 미탑승 시 No-Show 처리로 환불 불가합니다.
* 서비스 예약 당일 천재지변으로 인하여 취소 시 100% 전액 환불(결항 확인서 제줄必) 가능합니다.`,
  },
];
