import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import Jeju01 from 'assets/jeju01.jpg';
import { AiFillMessage, AiFillPhone } from 'react-icons/ai';
import { Button } from '@chakra-ui/react';
import ContactTypeCard from 'components/ContactTypeCard';
import ContactTypeContainer from 'components/ContactTypeContainer';
import channelData from 'data/ChannelData';

export default function Guide() {
  return (
    <MainLayout>
      <main className="pt-16 pb-16">
        <section className="px-9 py-10 md:pt-28 md:pb-20 md:text-center">
          <div className="rounded-full text-sm text-gray-500 font-semibold bg-gray-100 inline-block px-3 py-1 mb-4">
            이용안내
          </div>
          <h2 className="text-2xl font-light mb-3 md:text-4xl md:leading-snug md:mb-10">
            The편한여행 고객센터가
            <br />
            도와드리겠습니다
          </h2>
          <div className="text-sm font-light md:text-base">
            The편한여행 서비스 및 제주여행에 관해 편하게 문의해주세요.{' '}
            <br className="hidden md:block" />
            고객님의 입장이 되어 친절하게 도와드리겠습니다.
          </div>
        </section>
        <section className="px-6 md:p-0 mb-4 md:mb-8 flex flex-col gap-y-4">
          <ContactTypeContainer
            title="카카오톡 상담"
            time="24시간 365일"
            desc="The편한여행 카카오톡채널을 친구추가하시면 카카오톡으로 언제든 편리하게 문의하실 수 있습니다."
            buttonIcon={<AiFillMessage />}
            buttonLabel="바로가기"
            buttonUrl={channelData.kakao}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <ContactTypeCard
                title="챗봇상담"
                time="24시간 365일"
                desc="채팅하기 화면에서 문의내용을 입력하시면 바로 답변해 드립니다."
              />
              <ContactTypeCard
                title="톡상담(상담원 연결)"
                time="24시간 365일"
                desc="챗봇 채팅하기 화면에서 [상담원으로 전환하기]를 선택해주세요."
              />
            </div>
          </ContactTypeContainer>
          <ContactTypeContainer
            title="전화 상담"
            time="평일 10:00 - 20:00"
            desc="The편한여행 서비스, 호텔, 렌터카, 제휴 상품에 대해 문의하실 수
              있습니다."
            buttonIcon={<AiFillPhone />}
            buttonLabel="통화하기"
            buttonUrl="tel:07087351000"
          />
        </section>
      </main>
    </MainLayout>
  );
}
