import React from 'react';
import { FaInstagram, FaBlog } from 'react-icons/fa';
import { RiKakaoTalkFill } from 'react-icons/ri';
import { SiNaver } from 'react-icons/si';
import channelData from 'data/ChannelData';
import SocialLink from './SocialLink';

export default function Footer() {
  return (
    <footer className="bg-gray-100 px-9 py-12 text-gray-500 text-xs">
      <div className="md:max-w-screen-lg mx-auto">
        <div className="flex flex-col md:flex-row md:justify-between gap-y-5">
          <div id="left">
            <h3 className="font-bold text-sm mb-3">주식회사 드림코퍼레이션</h3>
            <div className="flex flex-col gap-1 mb-4">
              <p>대표자: 정순우 | 제주특별자치도 제주시 우정로 7길 24, 208호</p>
              <p>사업자등록번호: 608-86-11246 | 통신판매업신고번호: 제2020-제주노형-0126호</p>
              <p>
                개인정보보호책임자: 장수진(
                <a href="mailto:dreamcorp.trip@gmail.com" className="text-gray-700 underline">
                  dreamcorp.trip@gmail.com
                </a>
                )
              </p>
            </div>
            <div className="flex items-center gap-2 font-bold">
              <a href="#">이용약관</a>
              <div className="w-0.5 h-3 bg-gray-300" />
              <a href="#">개인정보처리동의</a>
            </div>
          </div>
          <div id="right">
            <div className="flex gap-2">
              <SocialLink url={channelData.instagram}>
                <FaInstagram size={20} color="white" />
              </SocialLink>
              <SocialLink url={channelData.kakao}>
                <RiKakaoTalkFill size={20} color="white" />
              </SocialLink>
              <SocialLink url={channelData.smartstore}>
                <SiNaver size={20} color="white" />
              </SocialLink>
              <SocialLink url={channelData.blog}>
                <div className="text-white font-bold w-5 h-5 flex justify-center items-center text-xl">
                  B
                </div>
              </SocialLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
