import React from 'react';
import PropTypes from 'prop-types';

export default function SocialLink({ url, children }) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className="rounded-full bg-gray-400 md:hover:bg-gray-300 transition p-3">
        {children}
      </div>
    </a>
  );
}

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
