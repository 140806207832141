import React, { useState } from 'react';
import {
  Field,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  Input,
  Stack,
  Button,
  Radio,
  RadioGroup,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { RadioGroupControl, SelectControl } from 'formik-chakra-ui';
import MainLayout from 'components/Layouts/MainLayout';
import Step from 'components/Layouts/Step';
import * as Yup from 'yup';
import { HiOutlineDocumentText } from 'react-icons/hi';
import axios from 'axios';

export default function Coop() {
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const toast = useToast();
  const handleToast = (status, title, description) => {
    toast({
      title,
      position: 'top',
      description,
      status,
      duration: '7500',
      isClosable: true,
    });
  };
  const handleSubmit = (content) => {
    setIsSubmitButtonLoading(true);
    axios({
      method: 'post',
      url: 'https://api.traveleasy.kr/contact_us',
      data: content,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => {
        handleToast(
          'success',
          '제휴제안이 성공적으로 제출되었습니다',
          '귀중한 제안에 감사드리며, 검토 후 연락드리겠습니다.',
        );
        setIsSubmitButtonLoading(false);
      })
      .catch((err) => {
        handleToast('error', '에러가 발생했습니다', err);
      });
  };

  const formLabelStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '12px',
  };
  return (
    <MainLayout>
      <main className="pt-16 pb-24 w-full md:max-w-screen-sm md:mx-auto">
        <section className="px-9 py-10 md:pt-28 md:pb-20 md:text-center">
          <div className="rounded-full text-sm text-gray-500 font-semibold bg-gray-100 inline-block px-3 py-1 mb-4">
            제휴문의
          </div>
          <h2 className="text-2xl font-light mb-3 md:text-4xl md:leading-snug  md:mb-10">
            다양한 제휴기회를 기다리고 있습니다
          </h2>
          <div className="text-sm md:text-center md:text-base">
            The편한여행은 고객에게 새로운 가치를 전달하고
            <br className="hidden md:block" /> 더 편한 제주여행을 제공할 기회를 모색하고 있습니다.
          </div>
        </section>

        <section className="flex flex-col gap-y-10 px-9 mb-10 w-full md:px-0 md:max-w-screen-sm md:mx-auto">
          <Step number="1" title="제안등록" body="제휴 제안 내용을 하단의 양식에 입력해주세요." />
          <Step number="2" title="제안검토" body="등록하신 내용은 담당자가 상세하게 검토합니다." />
          <Step
            number="3"
            title="결과회신"
            body="제휴제안 내용의 검토가 완료되면, 남겨주신 연락처를 통해 결과를 회신드립니다."
          />
        </section>
        <Divider />
        <section className="p-9">
          <Formik
            initialValues={{
              type: '',
              company_name: '',
              name: '',
              phone: '',
              email: '',
              website: '',
              division: '',
              title: '',
              content: '',
            }}
            validationSchema={Yup.object({
              type: Yup.string().required('필수 항목입니다'),
              company_name: Yup.string().required('필수 항목입니다'),
              name: Yup.string().required('필수 항목입니다'),
              phone: Yup.string().required('필수 항목입니다'),
              email: Yup.string()
                .required('필수 항목입니다')
                .email('유효한 이메일 주소를 입력해주세요'),
              website: Yup.string(),
              division: Yup.string().required('필수 항목입니다'),
              title: Yup.string().required('필수 항목입니다'),
              content: Yup.string().required('필수 항목입니다'),
            })}
            onSubmit={(values) => {
              const bodyFormData = new FormData();
              for (const [key, value] of Object.entries(values)) {
                bodyFormData.append(key, value);
              }
              handleSubmit(bodyFormData);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} className="w-full md:max-w-screen-sm md:mx-auto">
                <div className="grid grid-cols-1 gap-y-8 mb-8">
                  <div className="flex flex-col gap-y-3">
                    <div className="self-center rounded-full w-14 h-14 bg-gray-100 flex justify-center items-center">
                      <HiOutlineDocumentText className="text-gray-500" size={28} />
                    </div>
                    <h3 className="text-center font-bold text-lg">제휴제안 양식</h3>
                  </div>
                  <FormControl id="type" isRequired>
                    <FormLabel {...formLabelStyle}>업체구분</FormLabel>
                    <RadioGroupControl name="type">
                      <div className="w-full flex flex-col">
                        <div className="grid grid-cols-2 gap-x-4 gap-y-2 md:grid-cols-4">
                          <Radio colorScheme="primary" value="PUBLIC">
                            공공기관
                          </Radio>
                          <Radio colorScheme="primary" value="ENTERPRISE">
                            기업
                          </Radio>
                          <Radio colorScheme="primary" value="PERSONAL">
                            개인
                          </Radio>
                          <Radio colorScheme="primary" value="ETC">
                            기타
                          </Radio>
                        </div>
                      </div>
                    </RadioGroupControl>
                    <FormErrorMessage>{formik.errors.type}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="company_name"
                    isInvalid={formik.errors.company_name && formik.touched.company_name}
                    isRequired
                  >
                    <FormLabel {...formLabelStyle}>회사(기관)명</FormLabel>
                    <Input
                      type="text"
                      placeholder="드림코퍼레이션"
                      {...formik.getFieldProps('company_name')}
                    />
                    <FormErrorMessage>{formik.errors.company_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="name"
                    isInvalid={formik.errors.name && formik.touched.name}
                    isRequired
                  >
                    <FormLabel {...formLabelStyle}>제안자명</FormLabel>
                    <Input type="text" placeholder="홍길동" {...formik.getFieldProps('name')} />
                    <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="phone"
                    isInvalid={formik.errors.phone && formik.touched.phone}
                    isRequired
                  >
                    <FormLabel {...formLabelStyle}>전화번호</FormLabel>
                    <Input
                      type="tel"
                      placeholder="- 없이 숫자만 입력해주세요."
                      {...formik.getFieldProps('phone')}
                    />
                    <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="email"
                    isInvalid={formik.errors.email && formik.touched.email}
                    isRequired
                  >
                    <FormLabel {...formLabelStyle}>이메일</FormLabel>
                    <Input
                      type="email"
                      placeholder="email@naver.com"
                      {...formik.getFieldProps('email')}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="website"
                    isInvalid={formik.errors.website && formik.touched.website}
                  >
                    <FormLabel {...formLabelStyle}>홈페이지(선택입력)</FormLabel>
                    <Input
                      type="text"
                      placeholder="example.com"
                      {...formik.getFieldProps('website')}
                    />
                    <FormErrorMessage>{formik.errors.website}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="division"
                    isInvalid={formik.errors.division && formik.touched.division}
                    isRequired
                  >
                    <FormLabel {...formLabelStyle}>제휴구분</FormLabel>
                    <SelectControl
                      name="division"
                      selectProps={{ placeholder: '제휴구분을 선택하세요' }}
                    >
                      <option value="GOODS">상품 제휴</option>
                      <option value="MARKETING">마케팅/프로모션 제휴</option>
                      <option value="COMPANY">전사 제휴</option>
                      <option value="APP">앱서비스 제휴</option>
                    </SelectControl>
                  </FormControl>
                  <FormControl
                    id="title"
                    isInvalid={formik.errors.title && formik.touched.title}
                    isRequired
                  >
                    <FormLabel {...formLabelStyle}>제휴제목</FormLabel>
                    <Input
                      type="text"
                      placeholder="제목을 입력해주세요"
                      {...formik.getFieldProps('title')}
                    />
                    <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="content"
                    isInvalid={formik.errors.content && formik.touched.content}
                    isRequired
                  >
                    <FormLabel {...formLabelStyle}>제휴내용</FormLabel>
                    <Textarea {...formik.getFieldProps('content')} />
                    <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
                  </FormControl>
                  <div className="flex justify-center w-full md:w-60 md:mx-auto">
                    <Button
                      type="submit"
                      fontSize="14px"
                      size="lg"
                      width="100%"
                      colorScheme="primary"
                      color="black"
                      isLoading={isSubmitButtonLoading}
                    >
                      제휴제안 등록하기
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </section>
      </main>
    </MainLayout>
  );
}
