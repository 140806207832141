module.exports = [
  {
    id: 1,
    title: 'The편한여행 소개',
    url: '/about',
    submenu: [
      { id: 11, title: '브랜드', url: '/about/brand' },
      { id: 12, title: '제휴문의', url: '/about/coop' },
    ],
  },
  {
    id: 2,
    title: '서비스 안내',
    url: '/service',
    submenu: [
      { id: 21, title: 'The편한여행', url: '/service/easytravel' },
      { id: 22, title: '제휴서비스', url: '/service/partner' },
    ],
  },
  {
    id: 3,
    title: '고객센터',
    url: '/help',
    submenu: [
      { id: 31, title: '이용안내', url: '/help/guide' },
      { id: 32, title: '자주 묻는 질문', url: '/help/faq' },
    ],
  },
];
