import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Brand from 'pages/About/Brand';
import Coop from 'pages/About/Coop';
import Home from 'pages/Home';
import EasyTravel from 'pages/Service/EasyTravel';
import Partner from 'pages/Service/Partner';
import Guide from 'pages/Help/Guide';
import Faq from 'pages/Help/Faq';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about/brand" component={Brand} />
        <Route path="/about/coop" component={Coop} />
        <Redirect from="/about" to="/about/brand" />
        <Route path="/service/easytravel" component={EasyTravel} />
        <Route path="/service/partner" component={Partner} />
        <Redirect from="/service" to="/service/easytravel" />
        <Route path="/help/guide" component={Guide} />
        <Route path="/help/faq" component={Faq} />
        <Redirect from="/help" to="/help/guide" />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
