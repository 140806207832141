import React, { useState } from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import Jeju01 from 'assets/jeju01.jpg';
import { AiFillMessage, AiFillPhone } from 'react-icons/ai';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import ContactTypeCard from 'components/ContactTypeCard';
import ContactTypeContainer from 'components/ContactTypeContainer';
import faqData from 'data/FaqData';
import categoryList from 'data/FaqCateogory';

export default function Faq() {
  const [selectedCategoryId, setSelectedCategoryId] = useState(1);
  return (
    <MainLayout>
      <main className="pt-16 pb-16">
        <section className="px-9 py-10 md:pt-28 md:pb-20 md:text-center bg-primary">
          <div
            className="rounded-full text-sm font-semibold inline-block px-3 py-1 mb-4"
            style={{
              color: 'rgba(0, 0, 0, 0.7)',
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            자주 묻는 질문
          </div>
          <h2 className="text-2xl font-light mb-3 md:text-4xl md:leading-snug">
            무엇을 도와드릴까요?
          </h2>
        </section>
        <section className="pt-8 md:pt-12 md:min-h-500">
          <div className="md:max-w-screen-md mx-auto">
            <Tabs
              onChange={(index) => {
                setSelectedCategoryId(index + 1);
              }}
              variant="soft-rounded"
              colorScheme="gray"
              size="sm"
              className="px-4 mb-4 md:px-0"
            >
              <TabList>
                {categoryList.map((item) => (
                  <Tab key={item.id} _focus={{ outline: 'none' }}>
                    {item.title}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
            <Accordion allowToggle size="lg">
              {faqData
                .filter((item) => selectedCategoryId === item.category)
                .map((item) => (
                  <AccordionItem key={item.id}>
                    <AccordionButton
                      _expanded={{ bg: 'white' }}
                      _focus={{ outline: 'none' }}
                      height="64px"
                      backgroundColor="white"
                    >
                      <div className="flex-1 text-left font-semibold">{item.title}</div>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel className="text-sm whitespace-pre-wrap">
                      {item.body}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
        </section>
      </main>
    </MainLayout>
  );
}
