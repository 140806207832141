import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import Jeju01 from 'assets/jeju01.jpg';
import EasyTrip from 'assets/easytrip.jpg';
import History from 'components/Layouts/History';
import HistoryData from 'data/HistoryData';
import orgInno from 'assets/org_inno.svg';
import orgJstartup from 'assets/org_j-startup.svg';
import orgJto from 'assets/org_jto.svg';
import orgKtour from 'assets/org_k-tour.svg';
import orgKosme from 'assets/org_kosme.png';

export default function Brand() {
  return (
    <MainLayout>
      <main className="pt-16">
        <section className="px-9 py-10 md:pt-28 md:pb-20 md:text-center">
          <div className="rounded-full text-sm text-gray-500 font-semibold bg-gray-100 inline-block px-3 py-1 mb-4">
            브랜드
          </div>
          <h2 className="text-2xl font-light mb-3 md:text-4xl md:leading-snug  md:mb-10">
            여행을 더 쉽게, <br className="md:hidden" />
            더 편하게 즐기는
            <br />
            여행편의서비스
          </h2>
          <div className="text-sm md:text-center md:text-base">
            The편한여행은 제주를 방문하는 여행객에 집중하고, <br className="hidden md:block" />
            여행객이 온전히 여행을 즐길 수 있는 맞춤형 서비스를 제공합니다.
          </div>
        </section>
        <section>
          <img src={EasyTrip} alt="jeju" className="object-cover object-top w-full md:h-500" />
        </section>
        <section>
          <History
            HistoryData={HistoryData}
            className="w-full md:max-w-lg md:mx-auto md:px-0 md:py-16"
          />
        </section>
        <section className="px-4 py-12 text-center bg-white">
          <div className="flex flex-wrap gap-4 mb-16 items-center justify-center md:flex-row md:px-0 md:grid-cols-5 md:max-w-4xl md:mx-auto">
            <img src={orgKtour} alt="한국관광공사" className="h-11 -mt-4" />
            <img src={orgJto} alt="제주관광공사" className="h-11" />
            <img src={orgJstartup} alt="J-Start Up" className="h-14 mt-2 mb-5 md:-mt-2 md:mr-2" />
            <img src={orgInno} alt="제주창조경제혁신센터" className="h-10" />
            <img src={orgKosme} alt="청년창업사관학교" className="h-5" />
          </div>
        </section>
      </main>
    </MainLayout>
  );
}
