import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';

const theme = extendTheme({
  colors: {
    primary: {
      500: '#F8BFD8',
    },
    secondary: {
      500: '#BFF8EE',
    },
  },
  components: {
    Accordion: {
      sizes: {
        lg: {
          h: '56px',
        },
      },
    },
  },
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root'),
);
