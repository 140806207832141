import React from 'react';
import PropTypes from 'prop-types';
import { FiClock } from 'react-icons/fi';
import { Button } from '@chakra-ui/react';

export default function ContactTypeContainer({
  title,
  time,
  desc,
  buttonIcon,
  buttonLabel,
  buttonUrl,
  children,
  className,
}) {
  const openLink = (link) => {
    window.open(link);
  };
  return (
    <div
      className={`flex flex-col w-full gap-3 bg-gray-100 rounded-3xl p-6 md:max-w-2xl mx-auto ${className}`}
    >
      <div className="flex justify-between items-center gap-4">
        <div className="flex gap-2">
          <h3 className="font-bold text-lg">{title}</h3>
        </div>
        <Button
          borderRadius="999px"
          backgroundColor="gray.700"
          color="white"
          fontSize="14px"
          fontWeight="bold"
          _hover={{ backgroundColor: 'gray.600' }}
          leftIcon={buttonIcon}
          onClick={() => {
            openLink(buttonUrl);
          }}
        >
          {buttonLabel}
        </Button>
      </div>
      <div>
        <div className="inline-flex items-center gap-x-1 rounded-full text-xs text-gray-700 font-semibold mb-2">
          <FiClock size={15} />
          <span>{time}</span>
        </div>
        <div className="text-sm text-gray-700">{desc}</div>
      </div>
      {children}
    </div>
  );
}

ContactTypeContainer.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  buttonIcon: PropTypes.node.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

ContactTypeContainer.defaultProps = {
  children: '',
  className: '',
};
