import React, { useState, useEffect } from 'react';
import Dahama from 'assets/dahama.svg';
import { Button, Menu, MenuButton, MenuList, MenuItem, useDisclosure } from '@chakra-ui/react';
import { HiDownload } from 'react-icons/hi';
import GooglePlay from 'assets/googleplay.svg';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';
import { RiKakaoTalkFill } from 'react-icons/ri';
import MainLayout from 'components/Layouts/MainLayout';
import openAppLink from 'utility/openAppLink';
import Jeju02 from 'assets/jeju02.jpg';
import award01 from 'assets/award01.svg';
import award02 from 'assets/award02.svg';
import award03 from 'assets/award03.svg';
import award04 from 'assets/award04.svg';
import Testimonial from 'components/Testimonial';
import Mockup from 'assets/app_main_mockup.png';
import channelData from 'data/ChannelData';
import OrmssiCircle from 'assets/ormssi_circle.png';
import OrmssiTitle from 'assets/ormssi_title.png';

export default function Home() {
  const [userAgent, setUserAgent] = useState('');

  const openOrmssi = () => {
    window.open('https://oreumssi.com/', '_blank');
  };

  useEffect(() => {
    if (window.navigator.userAgent.match('Android')) {
      setUserAgent('android');
    } else {
      setUserAgent('apple');
    }
  }, []);

  return (
    <MainLayout>
      <main>
        <section className="bg-primary py-16 text-2xl md:pb-0 md:h-600">
          <div id="container" className="w-full h-full flex flex-col md:flex-row md:m-auto md:max-w-screen-lg">
            {/* 오름씨프렌즈 링크 */}
            <div className="px-6 py-4 md:hidden cursor-pointer">
              <button type="button" className="w-full bg-white rounded-2xl shadow-lg p-4 flex gap-x-4 items-center relative" onClick={openOrmssi}>
                <img src={OrmssiCircle} alt="오름씨프렌즈" className="w-14 h-14" />
                <img src={OrmssiTitle} alt="오름씨프렌즈" className="h-10 -mb-1" />
                <FiChevronRight className="absolute right-4" />
              </button>
            </div>
            <div id="left" className="pt-10 md:pt-0 md:flex-1 md:flex">
              <div className="self-center w-full md:ml-8">
                <h1 className="text-3xl font-bold leading-10 text-center mb-6 md:text-left md:text-5xl md:leading-tight">
                  제주를 여행하는
                  <br />
                  가장 편리한 방법
                </h1>
                <div className="text-center text-sm mb-6 md:text-left md:text-base">
                  여행객이 온전히 여행에만 집중할 수 있도록
                  <br />
                  맞춤형 서비스를 제공합니다.
                </div>
                <div className="hidden w-5/6 md:flex gap-2">
                  <Menu>
                    <MenuButton
                      as={Button}
                      leftIcon={<HiDownload size={24} />}
                      flex="1"
                      backgroundColor="white"
                      padding="8"
                      borderRadius="16"
                      // border="1px solid rgb(0 0 0 / 0.75)"
                      boxShadow="4px 4px 0 rgb(0 0 0 / 0.2)"
                      transition="all 0.2s ease-in-out"
                      _hover={{ bg: 'white', boxShadow: '6px 6px 0 rgb(0 0 0 / 0.2)' }}
                      _active={{ bg: 'white', boxShadow: '2px 2px 0 rgb(0 0 0 / 0.2)', filter: 'brightness(0.9)' }}
                    >
                      앱 다운로드
                    </MenuButton>
                    <MenuList className="text-sm">
                      <MenuItem
                        onClick={() => {
                          openAppLink('android');
                        }}
                      >
                        <img src={GooglePlay} alt="google play" className="w-3 h-3 mr-3" />
                        Google Play 다운로드
                      </MenuItem>
                      <MenuItem
                        icon={<FaApple />}
                        onClick={() => {
                          openAppLink('apple');
                        }}
                      >
                        App Store 다운로드
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Button
                    type="button"
                    leftIcon={<RiKakaoTalkFill size={24} />}
                    onClick={() => {
                      window.open(channelData.kakao, '_blank');
                    }}
                    flex="1"
                    backgroundColor="#ffe812"
                    padding="8"
                    borderRadius="16"
                    // border="1px solid rgb(0 0 0 / 0.75)"
                    boxShadow="4px 4px 0 rgb(0 0 0 / 0.2)"
                    transition="all 0.2s ease-in-out"
                    _hover={{ bg: '#ffe812', boxShadow: '6px 6px 0 rgb(0 0 0 / 0.2)', filter: 'brightness(1.05)' }}
                    _active={{ bg: '#ffe812', boxShadow: '2px 2px 0 rgb(0 0 0 / 0.2)', filter: 'brightness(0.9)' }}
                  >
                    1:1 문의
                  </Button>
                </div>
              </div>
            </div>
            <div id="right" className="md:flex-1 md:relative">
              <img src={Dahama} alt="dahama" className="w-60 h-60 object-cover object-top m-auto md:absolute md:bottom-0 md:w-10/12 md:h-5/6" />
              <div className="flex text-center md:hidden px-6 gap-2 relative">
                {/* <div
                  className="absolute left-0 right-0 -top-8 h-8 z-10"
                  style={{
                    background: 'linear-gradient(0deg, rgba(248,191,216,1) 10%, rgba(248,191,216,0) 100%)',
                  }}
                /> */}
                <Button
                  flex="1"
                  leftIcon={userAgent === 'apple' ? <FaApple size={24} /> : <img src={GooglePlay} alt="google play" className="w-5 h-5" />}
                  backgroundColor="white"
                  fontWeight="bold"
                  width="83%"
                  height="70px"
                  fontSize="17px"
                  borderRadius="16"
                  // border="1px solid rgb(0 0 0 / 0.75)"
                  boxShadow="4px 4px 0 rgb(0 0 0 / 0.2)"
                  transition="all 0.2s ease-in-out"
                  onClick={() => {
                    openAppLink(userAgent);
                  }}
                >
                  앱 다운로드
                </Button>
                <Button
                  flex="1"
                  leftIcon={<RiKakaoTalkFill size={24} />}
                  backgroundColor="#ffe812"
                  fontWeight="bold"
                  width="83%"
                  height="70px"
                  fontSize="17px"
                  borderRadius="16"
                  // border="1px solid rgb(0 0 0 / 0.75)"
                  boxShadow="4px 4px 0 rgb(0 0 0 / 0.2)"
                  transition="all 0.2s ease-in-out"
                  onClick={() => {
                    window.open(channelData.kakao, '_blank');
                  }}
                  _active={{ bg: '#ffe812' }}
                  _hover={{ bg: '#ffe812' }}
                >
                  1:1 문의
                </Button>
              </div>
            </div>
          </div>
        </section>
        <div className="flex flex-col bg-gray-100">
          <section className="px-8 pt-12 text-center bg-white">
            <div className="grid grid-cols-2 px-5 md:px-0 justify-items-center md:grid-cols-4 md:max-w-xl md:mx-auto gap-4 mb-16">
              <img src={award01} alt="award01" />
              <img src={award02} alt="award02" />
              <img src={award03} alt="award03" />
              <img src={award04} alt="award04" />
            </div>
            <div className="text-gray-700 mb-3">제주도 여행을 계획 중이신가요?</div>
            <h3 className="font-bold text-2xl md:text-3xl mb-4">
              이동, 숙박, 렌탈, 장보기까지
              <br />
              모든 것을 저희에게 맡기고
              <br />
              여행에만 온전히 집중하세요.
            </h3>
            <div>
              <img src={Mockup} alt="The편한여행 앱" className="object-cover object-top w-full h-96 mx-auto md:w-96 md:h-500" />
            </div>
          </section>
          {/* <section
            className="relative h-screen md:h-600 bg-no-repeat bg-center bg-cover flex
            justify-center items-center"
            style={{
              backgroundImage: `url(${Jeju02})`,
            }}
          >
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-20" />
          </section> */}
        </div>
      </main>
    </MainLayout>
  );
}
