import React from 'react';
import PropTypes from 'prop-types';

export default function Step({ number, title, body }) {
  return (
    <div className="flex justify-center relative text-center">
      <div className="absolute -top-5 md:-top-6 w-8 h-8 md:w-10 md:h-10 border-4 box-content border-white text-lg font-bold rounded-full bg-primary flex items-center justify-center">
        {number}
      </div>
      <div className="w-full bg-gray-50 p-6 pt-12 rounded-lg">
        <div className="relative inline-block mb-2">
          <h4 className="font-bold">{title}</h4>
          <div className="bg-green-200 w-full h-2 absolute bottom-px mix-blend-multiply" />
        </div>
        <div className="text-sm text-gray-700">{body}</div>
      </div>
    </div>
  );
}

Step.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
