import React from 'react';
import PropTypes from 'prop-types';

export default function FeatureItem(props) {
  const { title, desc, image, className } = props;
  return (
    <div className={`${className} flex flex-col gap-4`}>
      <div className="rounded-none md:rounded-lg overflow-hidden" style={{ maxHeight: '256px' }}>
        <img
          src={image}
          alt={image}
          className="transform transition duration-700 md:hover:scale-105"
        />
      </div>
      {/* <div
        style={{ backgroundImage: `url(${image})` }}
        className="w-full h-60 rounded-lg bg-cover"
      /> */}
      <div className="px-6 md:px-0">
        <h4 className="font-bold text-lg mb-2">{title}</h4>
        <div className="text-gray-700">{desc}</div>
      </div>
    </div>
  );
}

FeatureItem.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
