import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import { AiOutlineCar } from 'react-icons/ai';
import { FaShippingFast } from 'react-icons/fa';
import { FiBox } from 'react-icons/fi';
import { BiTrip, BiTask } from 'react-icons/bi';
import FeatureIcon from 'components/FeatureIcon';
import { Box } from '@chakra-ui/react';
import Img from 'assets/jeju01.jpg';
import PickupDrop from 'assets/service_pickup.jpg';
import RentCar from 'assets/service_rentcar.jpg';
import Shopping from 'assets/service_shopping.jpg';
import Rental from 'assets/service_rental.jpg';
import Curation from 'assets/service_curation.jpg';
import FeatureSection from 'components/FeatureSection';
import Phone from 'assets/phone01.jpg';
import FeatureItem from 'components/FeatureItem';

export default function EasyTravel() {
  return (
    <MainLayout>
      <main>
        <Box className="pt-24 pb-12 px-6">
          <div>
            <div className="pt-8 mb-10 md:text-center">
              <div
                className="rounded-full text-sm font-semibold inline-block px-3 py-1 mb-4 bg-primary"
                style={{ color: 'rgba(0,0,0,0.7)' }}
              >
                여행편의서비스
              </div>
              <h2 className="text-4xl font-medium mb-6 md:font-light md:leading-tight">
                여행에만 집중하세요
              </h2>
              <div>단순 모빌리티 앱이 아닙니다. 여행을 위한 시간을 만들어 드립니다.</div>
            </div>
            <div className="grid grid-cols-4 w-full md:max-w-lg md:mx-auto">
              <FeatureIcon
                icon={<AiOutlineCar className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>이동 서비스</div>}
              />
              <FeatureIcon
                icon={<FaShippingFast className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>딜리버리</div>}
              />
              <FeatureIcon
                icon={<BiTask className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>렌탈</div>}
              />
              <FeatureIcon
                icon={<BiTrip className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>여행 큐레이션</div>}
              />
            </div>
          </div>
        </Box>

        <div className="max-w-screen-lg mx-auto md:px-8 md:pt-10 pb-20 grid md:grid-cols-2 gap-x-8 gap-y-10">
          <FeatureItem
            image={PickupDrop}
            title="픽업 & 드랍 서비스"
            desc="원하시는 목적지까지 안전하고 편리한 이동 서비스"
          />
          <FeatureItem
            image={RentCar}
            title="렌터카 딜리버리"
            desc="제주도 전지역 찾아가는 렌터카 딜리버리 서비스"
          />
          <FeatureItem
            image={Shopping}
            title="장보기"
            desc="불필요한 시간 소요를 줄여드리는 장보기 대행 서비스"
          />
          <FeatureItem
            image={Rental}
            title="렌탈 서비스"
            desc="편의용품 불편해소를 위한 렌탈 딜리버리 서비스"
          />
          <FeatureItem
            image={Curation}
            title="여행 큐레이션"
            desc="일정, 원하는 여행 타입에 따른 여행 코스 추천"
          />
        </div>
        {/* <section
          className="relative h-48 md:h-72 bg-no-repeat bg-center bg-cover flex
            justify-center items-center"
          style={{
            backgroundImage: `url(${Phone})`,
          }}
        >
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-20" />
          <div className="absolute text-white">
            <h3>제휴서비스도 만나보세요</h3>
            <div>상담을 통해 항공권, 숙박, 렌터카, 로컬몰까지 해결하세요.</div>
          </div>
        </section> */}
      </main>
    </MainLayout>
  );
}
