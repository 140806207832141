import React from 'react';
import PropTypes from 'prop-types';
import { FiClock } from 'react-icons/fi';

export default function ContactTypeCard({ title, time, desc, className }) {
  return (
    <div className={`bg-white p-4 rounded-lg shadow ${className}`}>
      <div className="flex justify-between">
        <h4 className="font-bold mb-2">{title}</h4>
      </div>
      <div className="font-light text-sm">{desc}</div>
    </div>
  );
}

ContactTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ContactTypeCard.defaultProps = {
  className: '',
};
