const openAppLink = (ua) => {
  let url;
  if (ua === 'apple') {
    url = 'https://apps.apple.com/kr/app/%EB%8D%94%ED%8E%B8%ED%95%9C%EC%97%AC%ED%96%89/id1580519212';
  }
  if (ua === 'android') {
    url = 'https://play.google.com/store/apps/details?id=com.dreamcorp';
  }
  window.open(url, '_blank');
};

export default openAppLink;
