import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Footer from 'components/Footer';
import Quickmenu from 'components/Quickmenu';
import Nav from '../Nav/Nav';

export default function MainLayout({ children }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hovered, setHovered] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const toggleHover = () => {
    setHovered((prevState) => !prevState);
  };
  const handleOpenAppLink = () => {};
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Nav className="z-50 fixed w-full" showBg />
      <div>{children}</div>
      <Quickmenu />
      <Footer />
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
