import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HiMenu, HiX, HiDownload } from 'react-icons/hi';
import MenuData from 'data/MenuData';
import { Menu, MenuButton, MenuList, MenuItem, Button, useDisclosure } from '@chakra-ui/react';
import ModalPortal from 'components/HOC/ModalPortal';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { MdOutlineOpenInNew } from 'react-icons/md';
import GooglePlay from 'assets/googleplay.svg';
import openAppLink from 'utility/openAppLink';
import NavMenuItem from './NavMenuItem';
import logoType from './logotype.svg';
import MobileNavMenu from './MobileNavMenu';

export default function Nav({ showBg, className }) {
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);
  const mobileMenuRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
    if (!expanded) {
      onOpen();
    } else {
      onClose();
    }
  };
  const openOrmssi = () => {
    window.open('https://oreumssi.com/', '_blank');
  };

  return (
    <div className={`flex flex-col justify-center border-b border-blackAlpha transition-all md:flex-row ${className}`} style={{ backgroundColor: showBg || expanded || hovered ? 'white' : '' }}>
      <nav className="flex items-center px-5 h-16 justify-between w-full md:max-w-screen-lg z-20">
        <Link to="/">
          <img src={logoType} alt="logo" className="w-auto h-5" />
        </Link>
        <button type="button" className="w-6 h-6 focus:outline-none md:hidden" onClick={toggleExpanded}>
          {expanded ? <HiX size={24} /> : <HiMenu size={24} />}
        </button>
        {/* Navigation Menu */}
        <div
          className="hidden md:flex gap-x-4 h-full items-center"
          onMouseEnter={() => {
            setHovered(true);
          }}
        >
          <button type="button" className="text-sm font-semibold px-3 flex items-center gap-x-2 cursor-pointer" onClick={openOrmssi}>
            오름씨프렌즈
            <MdOutlineOpenInNew className="text-gray-500" />
          </button>
          {MenuData.map((item) => (
            <NavMenuItem title={item.title} url={item.url} key={item.id} submenu={item.submenu} showSubmenu={hovered} />
          ))}
          <Menu>
            <MenuButton as={Button} leftIcon={<HiDownload />} size="md" colorScheme="secondary" fontSize="14px" color="black" onClick={onOpen}>
              앱 다운로드
            </MenuButton>
            <MenuList className="text-sm">
              <MenuItem
                onClick={() => {
                  openAppLink('android');
                }}
              >
                <img src={GooglePlay} alt="google play" className="w-3 h-3 mr-3" />
                Google Play 다운로드
              </MenuItem>
              <MenuItem
                icon={<FaApple />}
                onClick={() => {
                  openAppLink('apple');
                }}
              >
                App Store 다운로드
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </nav>
      {/* Submenu panel */}
      {hovered && (
        <div
          className="hidden md:block absolute top-16 bg-white w-full h-36 shadow-xl"
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
        />
      )}
      {/* Mobile menu */}
      {expanded && (
        <ModalPortal>
          <MobileNavMenu menuData={MenuData} />
        </ModalPortal>
      )}
    </div>
  );
}

Nav.propTypes = {
  className: PropTypes.string,
  showBg: PropTypes.bool,
};
Nav.defaultProps = {
  className: '',
  showBg: false,
};
