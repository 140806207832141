module.exports = [
  {
    year: 2021,
    months: [
      {
        month: 10,
        content: 'The편한여행 어플리케이션 출시',
      },
      {
        month: 3,
        content: 'The편한여행 출시 및 서비스 운영',
      },
      {
        month: 3,
        content: '청년창업사관학교 11기 선정',
      },
    ],
  },
  {
    year: 2020,
    months: [
      {
        month: 12,
        content: '제주관광공사 J-Start Up 지정 및 육성 협약',
      },
      {
        month: 12,
        content: '제주관광공사 J-Start UP 혁신상 수상',
      },
      {
        month: 10,
        content: '2019년 예비창업 패키지 최우수 기업 선정',
      },
      {
        month: 9,
        content: '제주창조경제혁신센터 보육기업 선정',
      },
      {
        month: 9,
        content: '도전 K-스타트업 2020 혁신창업리그 제주지역 최우수상 수상',
      },
      {
        month: 8,
        content: '제주테크노파크 기술사업화 코칭 PLUS 선정',
      },
    ],
  },
  {
    year: 2019,
    months: [
      {
        month: 9,
        content: '법인설립',
      },
      {
        month: 9,
        content: '2019년 예비창업 패키지 선정',
      },
    ],
  },
];
