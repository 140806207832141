import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';

import { MdFlight, MdOutlineCarRental } from 'react-icons/md';
import { IoBedSharp } from 'react-icons/io5';
import { FiShoppingBag } from 'react-icons/fi';
import { BiBed } from 'react-icons/bi';

import FeatureIcon from 'components/FeatureIcon';
import { Box } from '@chakra-ui/react';
import FeatureItem from 'components/FeatureItem';
import Hotel from 'assets/partner_hotel.jpg';
import LocalMall from 'assets/partner_localmall.jpg';
import Rentcar from 'assets/partner_rentcar.jpg';
import Airplane from 'assets/partner_airplane.jpg';

export default function Partner() {
  return (
    <MainLayout>
      <div>
        <Box className="pt-24 pb-12 px-6">
          <div>
            <div className="pt-8 mb-10 md:text-center">
              <div
                className="rounded-full text-sm font-semibold inline-block px-3 py-1 mb-4 bg-secondary"
                style={{ color: 'rgba(0,0,0,0.7)' }}
              >
                제휴서비스
              </div>
              <h2 className="text-4xl font-medium mb-6 md:font-light md:leading-tight">
                무엇이든 상담으로
                <br /> 간편하게 해결하세요
              </h2>
              <div>이동과 숙박, 상품 구매까지 올인원 서비스를 제공합니다.</div>
            </div>
            <div className="grid grid-cols-4 w-full md:max-w-lg md:mx-auto">
              <FeatureIcon
                icon={<MdFlight className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>항공권</div>}
              />
              <FeatureIcon
                icon={<BiBed className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>숙박</div>}
              />
              <FeatureIcon
                icon={<MdOutlineCarRental className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>렌터카</div>}
              />
              <FeatureIcon
                icon={<FiShoppingBag className="w-8 h-8 md:w-10 md:h-10" />}
                title={<div>로컬몰</div>}
              />
            </div>
          </div>
        </Box>
        <div className="max-w-screen-lg mx-auto md:px-8 md:pt-10 pb-20 grid md:grid-cols-2 gap-x-8 gap-y-10">
          <FeatureItem image={Airplane} title="항공" desc="원하는 일정으로 대리 예약 진행" />
          <FeatureItem
            image={Hotel}
            title="호텔"
            desc="호텔과의 협업 상품 판매, 호텔 + 픽업 & 드랍 결합상품"
          />
          <FeatureItem image={Rentcar} title="렌터카" desc="렌터카 제휴로 렌터카 대리 예약 가능" />
          <FeatureItem
            image={LocalMall}
            title="로컬몰"
            desc="제주를 대표하는 로컬기업의 상품 딜리버리, 판매대행"
          />
        </div>
      </div>
    </MainLayout>
  );
}
