import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
} from '@chakra-ui/react';

export default function MobileNavMenu({ menuData, className }) {
  const scrollTarget = useRef().current;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = 0;
    document.body.style.left = 0;
    document.body.style.right = 0;
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
    };
  }, []);

  return (
    <div
      ref={scrollTarget}
      className={` fixed top-16 left-0 right-0 h-full px-5 bg-white overflow-y-auto md:hidden ${className}`}
    >
      <div className="mb-5">
        <Accordion allowToggle size="lg">
          {menuData.map((item) => (
            <AccordionItem key={item.id}>
              <AccordionButton
                _expanded={{ bg: 'white' }}
                _focus={{ outline: 'none' }}
                height="64px"
                paddingLeft="0"
                paddingRight="0"
                backgroundColor="white"
              >
                <div className="flex-1 text-left font-semibold">{item.title}</div>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel paddingLeft="0" paddingRight="0">
                <ul>
                  {item.submenu.map((submenuItem) => (
                    <Link to={submenuItem.url} key={submenuItem.id}>
                      <li className="h-12 flex items-center text-md text-gray-700">
                        {submenuItem.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

MobileNavMenu.propTypes = {
  menuData: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

MobileNavMenu.defaultProps = {
  className: '',
};
