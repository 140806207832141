import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export default function NavMenuItem({ title, url, submenu, showSubmenu }) {
  const [mouseOver, setMouseOver] = useState(false);
  const handleMouseOver = () => {
    setMouseOver(true);
  };
  const handleMouseOut = () => {
    setMouseOver(false);
  };
  const activeClassName = 'border-black';
  return (
    <div className="relative h-full" style={{ marginBottom: '-1px' }}>
      <NavLink
        to={url}
        className={`text-sm flex justify-center items-center font-semibold px-3 py-2 h-full border-b-2 border-transparent ${
          mouseOver ? activeClassName : ''
        }`}
        style={{ minWidth: '108px' }}
        activeClassName={activeClassName}
        onMouseEnter={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {title}
      </NavLink>
      {submenu && showSubmenu && (
        <div className="absolute top-20 w-full text-center pt-6">
          <ul>
            {submenu.map((item) => (
              <li
                key={item.id}
                className="text-sm text-gray-700 font-medium h-8"
              >
                <NavLink to={item.url} className="hover:underline">
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

NavMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  submenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSubmenu: PropTypes.bool,
};

NavMenuItem.defaultProps = {
  showSubmenu: false,
};
