import React from 'react';
import PropTypes from 'prop-types';

export default function History({ HistoryData, className }) {
  return (
    <div className={`p-9 ${className}`}>
      {HistoryData.map((yearItem) => (
        <div
          className="flex flex-col mb-6 md:flex-row md:gap-x-12"
          key={yearItem.year}
        >
          <div className="text-lg font-bold mb-4 md:text-2xl">
            {yearItem.year}
          </div>
          <div className="md:pt-1">
            {yearItem.months.map((monthItem) => (
              <div className="flex mb-3" key={monthItem.content}>
                <div className="w-10 text-gray-500 font-medium text-sm">
                  {monthItem.month}월
                </div>
                <div className="flex-1 text-sm">{monthItem.content}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

History.propTypes = {
  HistoryData: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string.isRequired,
};
